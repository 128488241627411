<template>
  <div>
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Add Component</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header-->

      <!--begin::Card body-->
      <div class="card-body p-9">

        <div class="row mb-3" v-show="success">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully added component!
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-3 mb-3 mb-lg-0">
            <div class="mb-3 pb-3 border-bottom">
              <h3 class="fw-400">Product Details</h3>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label required fw-bold fs-6">Product Type</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.product_type_id}" value="COMPONENT" :readonly="true">
              <span class="text-danger" v-if="errors.value && errors.value.product_type_id">{{errors.value && errors.value.product_type_id[0]}}</span>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label required fw-bold fs-6">Product Code</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.code}" v-model="formData.code" placeholder="Enter product code">
              <span class="text-danger" v-if="errors.value && errors.value.code">{{errors.value && errors.value.code[0]}}</span>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">UDI</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.udi}" v-model="formData.udi" placeholder="Enter UDI">
              <span class="text-danger" v-if="errors.value && errors.value.udi">{{errors.value && errors.value.udi[0]}}</span>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label required fw-bold fs-6">Name</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.name}" v-model="formData.name" placeholder="Enter name">
              <span class="text-danger" v-if="errors.value && errors.value.name">{{errors.value && errors.value.name[0]}}</span>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Description</label>
              <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.description}" v-model="formData.description" placeholder="Enter description"></textarea>
              <span class="text-danger" v-if="errors.value && errors.value.description">{{errors.value && errors.value.description[0]}}</span>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label required fw-bold fs-6">Category</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.product_category_id}" @change="formData.product_category_id = $event.target.value">
                <option selected disabled>Select Category</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myProductCategories" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.product_category_id">{{errors.value && errors.value.product_category_id[0]}}</span>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Device Classification</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.device_classification_id}" @change="formData.device_classification_id = $event.target.value">
                <option selected disabled>Select Please</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myClassificationCategoies" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.device_classification_id">{{errors.value && errors.value.device_classification_id[0]}}</span>
            </div>
            <div class="form-group">
              <label class="col-form-label fw-bold fs-6">Obsolete</label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="formData.obsolete">
                <label class="form-check-label" for="flexSwitchCheckChecked"></label>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-3 mb-3 mb-lg-0">
            <div class="mb-3 pb-3 border-bottom">
              <h3 class="fw-400">Stock Control</h3>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Re-Order Level</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.reOrderLevel}" v-model="formData.reOrderLevel" placeholder="Enter re-order level">
              <span class="text-danger" v-if="errors.value && errors.value.reOrderLevel">{{errors.value && errors.value.reOrderLevel[0]}}</span>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Re-Order Quantity</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.reOrderQty}" v-model="formData.reOrderQty" placeholder="Enter re-order quantity">
              <span class="text-danger" v-if="errors.value && errors.value.reOrderQty">{{errors.value && errors.value.reOrderQty[0]}}</span>
            </div>
          </div>

          <div class="col-12 col-lg-3 mb-3 mb-lg-0">
            <div class="mb-3 pb-3 border-bottom">
              <h3 class="fw-400">Packaging</h3>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Items Per Carton</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.itemsPerCarton}" v-model="formData.itemsPerCarton" placeholder="Enter items per carton">
              <span class="text-danger" v-if="errors.value && errors.value.itemsPerCarton">{{errors.value && errors.value.itemsPerCarton[0]}}</span>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Cost / Carton</label>
              <input type="number" step="0.00001" class="form-control" :class="{'is-invalid' : errors.value && errors.value.costPerCarton}" v-model="formData.costPerCarton" placeholder="Enter cost per carton">
              <span class="text-danger" v-if="errors.value && errors.value.costPerCarton">{{errors.value && errors.value.costPerCarton[0]}}</span>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Currency</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.currency_id}" @change="formData.currency_id = $event.target.value">
                <option selected disabled>Select Currency</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myCurrencies" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.currency_id">{{errors.value && errors.value.currency_id[0]}}</span>
            </div>
          </div>

          <div class="col-12 col-lg-3 mb-3 mb-lg-0">
            <div class="mb-3 pb-3 border-bottom">
              <h3 class="fw-400">Notes</h3>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Note</label>
              <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.note}" v-model="formData.note" placeholder="Enter note"></textarea>
              <span class="text-danger" v-if="errors.value && errors.value.note">{{errors.value && errors.value.note[0]}}</span>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Attachment</label>
              <input type="file" class="form-control" :class="{'is-invalid' : errors.value && errors.value.attachment}" @change="fileAdded" />
              <span class="text-danger" v-if="errors.value && errors.value.attachment">{{errors.value && errors.value.attachment[0]}}</span>
            </div>

          </div>

        </div>
      </div>
      <!--end::Card body-->
      <div class="card-footer cursor-pointer">
        <!--begin::Card title-->
        <div class="text-end m-0">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder me-3 my-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Card title-->
      </div>
    </div>

  </div>
</template>
<script lang="ts">
import { reactive, ref, onMounted } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/ProductEnums.ts";
import { Actions as CurrencyActions } from "@/store/enums/CurrencyEnums.ts";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'ComponentAdd',
  setup(){

    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store.dispatch(Actions.GET_PRODUCT_CATEGORIES);
      store.dispatch(Actions.GET_DEVICE_CLASSIFICATION);
      store.dispatch(CurrencyActions.GET_CURRENCIES);
      setCurrentPageBreadcrumbs("Products",[
        {title : "Products",to : "/products/list"},
        {title : "New Component",to : "/products/component/add"}
      ]);
    });


    const submitButton = ref<HTMLElement | null>(null);

    const success = ref(false);

    const errors = reactive({
      value : {}
    });

    const formData = reactive({
      product_type_id : 1,
      code : '',
      udi : '',
      product_category_id : null,
      device_classification_id : null,
      obsolete : false,
      reOrderLevel : null,
      reOrderQty : null,
      itemsPerCarton : 1,
      costPerCarton : 0,
      currency_id : null,
      note : '',
      attachment : null
    })


    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.ADD_COMPONENT,formData).then(() => {
        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);
        setTimeout(function(){
          router.push('/products/list')
        },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    const fileAdded = (e) => {

      formData.attachment = e.target.files[0];

    }

    return {
      store,
      submitButton,
      formData,
      submitForm,
      errors,
      success,
      fileAdded
    }
  }
}
</script>
